import React from "react"
import "./index.scss"
import { SanityForm } from "@utils/globalTypes"
import { Col, Row, Button, Alert } from "react-bootstrap"
import { BaseBlockContent } from "@utils/richText"
import useResizeWidth from "@hooks/useResizeWidth"
import { useState } from "react"

type Props = {
  sanityForm: SanityForm
  name: string
}

const isBrowser = typeof window !== "undefined"

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const Form = ({ sanityForm, name }: Props) => {
  const { form, formTitle } = sanityForm
  const { width } = useResizeWidth()
  const [formData, setFormData] = useState({})
  const [alert, setAlert] = useState({
    show: false,
    variant: "success",
    message: "",
  })

  const formSubmit = (e) => {
    e.preventDefault()
    // Submits to netlify
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": e.target.getAttribute("name"),
        ...formData,
      }).toString(),
    })
      .then((res) => {
        if (res?.ok) {
          setAlert({
            show: true,
            variant: "success",
            message:
              "We received your inquiry and will get back to you as soon as we can. Thank you.",
          })
          setFormData({})
          setTimeout(() => {
            setAlert({
              show: false,
              variant: "success",
              message: "",
            })
          }, 5000)
        } else {
          setAlert({
            show: true,
            variant: "danger",
            message: "Something were wrong submiting the information",
          })
          setTimeout(() => {
            setAlert({
              show: false,
              variant: "success",
              message: "",
            })
          }, 5000)
        }
      })
      .catch((error) => {
        setAlert({
          show: true,
          variant: "danger",
          message: "Something were wrong in our end",
        })
        setTimeout(() => {
          setAlert({
            show: false,
            variant: "success",
            message: "",
          })
        }, 5000)
      })
  }

  return (
    <Row>
      <Col md={12} lg={6}>
        <div className="form-text-container form-text phone-class">
          {sanityForm?.title && <h1>{sanityForm?.title}</h1>}
          {width > 980 && sanityForm?.information && (
            <BaseBlockContent blocks={sanityForm?.information} />
          )}
        </div>
      </Col>
      <Col md={12} lg={6}>
        <div className="form-text-container form-container phone-class">
          {formTitle && <BaseBlockContent blocks={formTitle} />}
          <div>
            <form
              id={name}
              name={name}
              method="POST"
              data-netlify={true}
              data-netlify-honeypot="bot-field"
              onSubmit={formSubmit}
            >
              {form?.map(({ required, type, name, textArea }, index) =>
                textArea ? (
                  <textarea
                    key={index}
                    name={name.toLowerCase()}
                    id={name.toLowerCase().replace(" ", "")}
                    rows={3}
                    className="form-field"
                    placeholder={name}
                    required={required}
                    value={formData[name.toLowerCase()] || ""}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        [name.toLowerCase()]: e.target.value,
                      })
                    }
                  />
                ) : (
                  <input
                    key={index}
                    name={name.toLowerCase()}
                    id={name.toLowerCase().replace(" ", "")}
                    type={type.toLowerCase()}
                    className="form-field"
                    placeholder={name}
                    required={required}
                    value={formData[name.toLowerCase()] || ""}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        [name.toLowerCase()]: e.target.value,
                      })
                    }
                  />
                )
              )}
              <Row noGutters>
                {sanityForm.buttons.map((button, index) => (
                  <Button
                    variant="outline-link"
                    type={button.buttonType.toLowerCase() || "submit"}
                  >
                    {button.title}
                  </Button>
                ))}
              </Row>
            </form>
          </div>
          {sanityForm?.information && <Row></Row>}
          {width < 980 && sanityForm?.information && (
            <div className="form-text-small">
              <BaseBlockContent blocks={sanityForm?.information} />{" "}
            </div>
          )}
        </div>
      </Col>
      <Alert
        show={alert.show}
        variant={alert.variant}
        dismissible
        onClose={() => setAlert({ ...alert, show: false })}
        className="form-alert"
      >
        <p>{alert.message}</p>
      </Alert>
    </Row>
  )
}

export default Form
