import React from "react"
import { graphql, Link } from "gatsby"
import "./index.scss"
import { SanityGetInTouch } from "@utils/globalTypes"
import { BaseBlockContent } from "@utils/richText"
import SEO from "@components/SEO"
import Form from "@components/Form"
import ScrollUpButton from "@components/ScrollUpButton"
import { Row, Col, Image as Img } from "react-bootstrap"
import Image from "gatsby-image"
import useResizeWidth from "@hooks/useResizeWidth"

type Props = {
  data: {
    sanityGetInTouch: SanityGetInTouch
  }
  location: Location
}

const GetInTouchPage = ({ data: { sanityGetInTouch }, location }: Props) => {
  const { backgroundColor } = sanityGetInTouch
  const { width } = useResizeWidth()
  const metadata = {
    location: location.href,
    ...sanityGetInTouch?.seo,
  }

  return (
    <>
      <SEO {...metadata} />
      <section
        className="git-wrapper content-padding-top parent-padding"
        style={{ backgroundColor: backgroundColor || "#FFF5EB" }}
      >
        {sanityGetInTouch?._rawForm && (
          <section className="git-block-container git-padding-inline">
            {" "}
            <Form sanityForm={sanityGetInTouch._rawForm} name="get-in-touch" />
          </section>
        )}
        <Row
          className="git-block-container-address git-padding-inline"
          noGutters
        >
          {sanityGetInTouch?._rawContactInfo && (
            <Col md={12} lg={6}>
              <div className="git-address">
                <BaseBlockContent blocks={sanityGetInTouch?._rawContactInfo} />
              </div>
            </Col>
          )}
          <Col md={12} lg={6}>
            <div className="git-maps">
              <a
                target="_blank"
                rel="noopener"
                href={encodeURI(
                  `https://www.google.com/maps/search/?api=1&query=${
                    sanityGetInTouch?.address ||
                    `${sanityGetInTouch?.location?.lat}, ${sanityGetInTouch?.location?.lat}`
                  }`
                )}
              >
                <Image
                  fluid={sanityGetInTouch?.map?.asset?.fluid}
                  className="map-image"
                  alt={sanityGetInTouch.address}
                />
              </a>
            </div>
          </Col>
        </Row>
        <Row className="git-block-container git-padding-inline" noGutters>
          {sanityGetInTouch?.blocks?.map(({ _rawContent }, index) => (
            <Col md={12} lg={6} key={index}>
              <div className="git-block">
                <BaseBlockContent blocks={_rawContent} />
              </div>
            </Col>
          ))}
        </Row>
        {width > 640 ? (
          <ScrollUpButton type="dark" />
        ) : (
          <Row className="hwh-backTop-container">
            {" "}
            <ScrollUpButton
              type="dark"
              style={{
                position: "relative",
                opacity: 1,
                right: "initial",
                bottom: "initial",
              }}
            />{" "}
          </Row>
        )}
      </section>
    </>
  )
}

export default GetInTouchPage

export const query = graphql`
  query sanityGetInTouch($slug: String) {
    sanityGetInTouch(slug: { current: { eq: $slug } }) {
      seo {
        description
        keywords
        image
        title
        twitterCard
        twitterCreator
        twitterSite
      }
      title
      slug {
        current
      }
      backgroundColor
      _rawForm(resolveReferences: { maxDepth: 10 })
      address
      map {
        asset {
          fluid(maxWidth: 10000) {
            ...GatsbySanityImageFluid
          }
        }
      }
      location {
        alt
        lat
        lng
      }
      _rawContactInfo(resolveReferences: { maxDepth: 10 })
      blocks {
        _rawContent(resolveReferences: { maxDepth: 10 })
      }
    }
  }
`
